/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import JsQR from 'jsqr';
import React, {
  useEffect, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import Nnris from 'assets/images/nnris.png';
import Nutifood from 'assets/images/nutifood.png';
import Image from 'components/Image';

const Scan: React.FC = () => {
  const history = useHistory();
  const cameraRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let terminateWorker: () => void;
    let stopCamera: () => void;

    (async () => {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
          width: 600,
          height: 800,
        },
      });

      stopCamera = () => {
        mediaStream.getTracks().forEach((t) => t.stop());
      };

      if (!cameraRef.current) {
        return;
      }

      const cameraEl = cameraRef.current;

      cameraEl.srcObject = mediaStream;

      // cameraEl.addEventListener('loadedmetadata', async () => {
      //   const [loadingPromise, foundPromise, _terminateWorker] = DetectMaker(
      //     cameraEl,
      //   );
      //   terminateWorker = _terminateWorker;

      //   await loadingPromise;
      //   // setLoading(false);

      //   await foundPromise;
      //   history.push({
      //     pathname: '/preview',
      //     search: window.location.search,
      //   });
      // });
    })();

    return () => {
      if (terminateWorker) {
        terminateWorker();
      }
      if (stopCamera) {
        stopCamera();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tick = () => {
    if (canvasRef?.current && cameraRef?.current) {
      // const video = document.getElementById("camera");
      const video = cameraRef.current;
      const canvasEl = canvasRef.current;
      const ctx = canvasEl.getContext('2d');
      if (ctx && video) {
        ctx.drawImage(video, 0, 0, canvasEl.width, canvasEl.height);
        const imageData = ctx.getImageData(0, 0, canvasEl.width, canvasEl.height);
        if (imageData) {
          const code = JsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert',
          });
          if (code) {
            history.push({
              pathname: code.data,
              state: {
                isPG: true,
              },
            });
          }
        }
      }
    }
    requestAnimationFrame(tick);
  };
  useEffect(() => {
    requestAnimationFrame(tick);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-scan">
      <div className="panel">
        <div className="logo">
          <div className="nutifood">
            <Image imgSrc={Nutifood} ratio="nutifood" />
          </div>
          <div className="nnris">
            <Image imgSrc={Nnris} ratio="nnris" />
          </div>
        </div>

        <div className="box">
          <div className="box_camera">
            <video
              id="camera"
              ref={cameraRef}
              className="camera"
              controls={false}
              disableRemotePlayback
              playsInline
              autoPlay
              muted
            />
            <canvas ref={canvasRef} className="canvas" width="378" height="378" />
          </div>
        </div>
      </div>

    </div>
  );
};

export default Scan;
