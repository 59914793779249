/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import WrapButton from 'assets/images/button.svg';
import Icon from 'components/Icon';
import Image from 'components/Image';

export interface FormPayload {
  name: string;
  phone: string;
  address: string;
  family: string;
}

interface ErrorResponse {
  checkInDate?: string;
  code: number;
  customerId: number;
  message: string;
}

interface FormProps {
  onSubmit?: (data: FormPayload) => Promise<unknown>;
  triggerValid?: (data: number) => void;
}

const Form: React.FC<FormProps> = ({ onSubmit, triggerValid }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const method = useForm<FormPayload>({
    defaultValues: {
      name: '',
      phone: '',
      address: '',
      family: '',
    },
    resolver: yupResolver(yup.object({
      name: yup.string().required('Thông tin bắt buộc !!'),
      address: yup.string().required('Thông tin bắt buộc !!').min(6, 'Ít nhất 6 kí tự !!'),
      family: yup.string().required('Thông tin bắt buộc !!').matches(/^\d+$/, 'Không đúng định dạng !!'),
      phone: yup.string().required('Thông tin bắt buộc !!')
        .matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/, 'Không đúng định dạng !!'),
    })),
  });

  const submitForm = async (data: FormPayload) => {
    try {
      setLoading(true);
      if (onSubmit) {
        await onSubmit(data);
      }
      return null;
    } catch (error) {
      if (Array.isArray(error)) {
        const err = error.find((e) => e?.code === 1000) as ErrorResponse | undefined;
        if (err && err.customerId) {
          if (!err?.checkInDate && triggerValid) {
            triggerValid(err.customerId);
          }
          if (err?.checkInDate) {
            history.push(`/check-in/${err?.customerId}`);
          }
        }
      }
      return error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form">
      <FormProvider {...method}>
        <form onSubmit={method.handleSubmit(submitForm)} noValidate>
          <h2>THÔNG TIN KHÁCH HÀNG</h2>

          <div className="field">
            <label htmlFor="name">Họ tên</label>
            <Controller
              name="name"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <input autoComplete="off" type="text" {...field} id="name" name="name" />
                  {error?.message && (
                    <span>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          <div className="field">
            <label htmlFor="phone">Số điện thoại</label>
            <Controller
              name="phone"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <input autoComplete="off" type="number" pattern="\d*" {...field} id="phone" name="phone" />
                  {error?.message && (
                    <span>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          <div className="field">
            <label htmlFor="address">Địa chỉ</label>
            <Controller
              name="address"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <input autoComplete="off" type="text" {...field} id="address" name="address" />
                  {error?.message && (
                    <span>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          <div className="field">
            <label htmlFor="family">Tham dự cùng bao nhiêu người trong gia đình</label>
            <Controller
              name="family"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <input autoComplete="off" type="number" pattern="\d*" {...field} id="family" name="family" />
                  {error?.message && (
                    <span>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          <div className="submit">
            <button type="submit">
              <Image imgSrc={WrapButton} ratio="submit" />
              {!loading ? (
                <span>
                  GỬI THÔNG TIN
                </span>
              ) : (
                <Icon iconName="loading" />
              )}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Form;
