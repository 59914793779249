import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import WrapButton from 'assets/images/button.svg';
import Nnris from 'assets/images/nnris.png';
import NnrisBig from 'assets/images/nnris_big.png';
import Nutifood from 'assets/images/nutifood.png';
import Image from 'components/Image';
import WrapModal from 'components/Modal';
import { apiCheckIn } from 'services/request/customer';

const CheckIn: React.FC = () => {
  const [status, setStatus] = useState<'success' | 'error' | 'else'>();
  const [date, setDate] = useState<string>();
  const params = useParams<{slug: string}>();
  const history = useHistory();
  const location = useLocation<{isPG?: boolean}>();

  const [stt, setStt] = useState<number>(-1);

  useEffect(() => {
    if (params?.slug) {
      (async (): Promise<void> => {
        try {
          const res = await apiCheckIn(Number(params.slug));
          setStt(res.numberCheckin);
          setStatus('success');
        } catch (error) {
          if (Array.isArray(error)) {
            const err = error.find((e) => e?.code === 1001);
            const isValid = dayjs(err?.checkInDate).isValid();
            if (!!err && isValid) {
              setStatus('error');
              setDate(dayjs(err?.checkInDate).format('HH:mm DD/MM/YYYY'));
            }
          }
        }
      })();
    }
    return () => {
      setStatus(undefined);
    };
  }, [params?.slug]);

  return (
    <div className="p-home">
      <div className="panel">
        <div className="logo">
          <div className="nutifood">
            <Image imgSrc={Nutifood} ratio="nutifood" />
          </div>
          <div className="nnris">
            <Image imgSrc={Nnris} ratio="nnris" />
          </div>
        </div>

      </div>
      <div className="nnris_big">
        <Image imgSrc={NnrisBig} ratio="nnris_big" />
      </div>
      <WrapModal isOpen={status === 'success'}>
        <h2>
          MÃ XÁC THỰC HỢP LỆ
          <br />
          GỬI TẶNG BẠN 1 PHẦN
          <br />
          QUÀ TỪ CHƯƠNG TRÌNH
          <br />
          STT:
          {' '}
          {stt < 10 ? `0${stt}` : stt}
        </h2>
        {location?.state?.isPG && (
        <div className="back">
          <button type="button" onClick={() => history.goBack()}>
            <Image imgSrc={WrapButton} ratio="submit" />
            <span>
              QUAY LẠI
            </span>
          </button>
        </div>
        )}
      </WrapModal>

      <WrapModal isOpen={status === 'error'}>
        <h2>
          BẠN ĐÃ CHECK IN &
          <br />
          NHẬN QUÀ TẶNG
          <br />
          CHƯƠNG TRÌNH
          <br />
          VÀO LÚC
          {' '}
          {date}
        </h2>
        {location?.state?.isPG && (
        <div className="back">
          <button type="button" onClick={() => history.goBack()}>
            <Image imgSrc={WrapButton} ratio="submit" />
            <span>
              QUAY LẠI
            </span>
          </button>
        </div>
        )}
      </WrapModal>
    </div>
  );
};

export default CheckIn;
