import axiosInstance from 'services/common/instance';

export interface SaveCheckInPayload {
  name: string;
  phone: string;
  address: string;
  participants: number;
}

export interface ISaveCheckIn {
  address: string;
  createdAt: string;
  id: number;
  name: string;
  participants: number;
  phone: string;
  updatedAt: string;
}

export interface ICheckIn extends ISaveCheckIn {
  checkinAt: string;
  numberCheckin: number;
}

export const apiSaveCheckIn = async (params: SaveCheckInPayload): Promise<ISaveCheckIn> => {
  const res = await axiosInstance.post('customers', params);
  return res.data.data;
};

export const apiCheckIn = async (id: number): Promise<ICheckIn> => {
  const res = await axiosInstance.post(`customers/${id}/check-in`);
  return res.data.data;
};
