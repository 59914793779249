import QrCode from 'qrcode';
import React, { useEffect, useState } from 'react';

// import QrDummy from 'assets/images/qr.jpg';
import WrapQr from 'assets/images/wrap_qr.png';
import Image from 'components/Image';

interface QRProps {
  id?: number;
}

const QR: React.FC<QRProps> = ({ id }) => {
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    QrCode.toDataURL(`/check-in/${id}`, (err, url) => {
      if (err) {
        throw new Error('Something wrong !!');
      }

      setQrCode(url);
    });
  }, [id]);

  return (
    <div className="qr">
      <h2>
        Chào mừng bạn đến với
      </h2>
      <h2 className="highlight">
        VIỆN NGHIÊN CỨU DINH DƯỠNG
        {' '}
        <br />
        NUTIFOOD THỤY ĐIỂN
      </h2>
      <div className="thumbnail">
        <Image imgSrc={WrapQr} ratio="1x1" />
        <div className="qr_main">
          <Image imgSrc={qrCode} ratio="1x1" />
        </div>
      </div>
      <h2>
        Hãy mang mã QR code này
        <br />
        đến khu vực quà tặng
        <br />
        để nhận một phần quà
        <br />
        từ chương trình
      </h2>
    </div>
  );
};

export default QR;
