import React from 'react';
import Modal from 'react-modal';

import mapModifiers from 'utils/functions';

interface ModalProps {
  handleClose?: () => void;
  modifiers?: BackgroundStyle[];
  isOpen: boolean;
}

const WrapModal: React.FC<ModalProps> = ({
  children,
  modifiers,
  handleClose,
  isOpen,
}) => (
  <Modal
    ariaHideApp={false}
    onRequestClose={handleClose}
    closeTimeoutMS={250}
    isOpen={isOpen}
    className="o-modal"
    portalClassName={mapModifiers('o-modal-portal', modifiers, isOpen && 'open')}
    overlayClassName="o-modal_overlay"
    bodyOpenClassName="reactmodal-body-open"
    htmlOpenClassName="reactmodal-html-open"
  >
    <div className="o-modal_container">
      <div className="o-modal_content">{children}</div>
    </div>
  </Modal>
);

export default WrapModal;
