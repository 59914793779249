/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import Form, { FormPayload } from './form';
import QR from './qr';

import Nnris from 'assets/images/nnris.png';
import NnrisBig from 'assets/images/nnris_big.png';
import Nutifood from 'assets/images/nutifood.png';
import Image from 'components/Image';
import { apiSaveCheckIn } from 'services/request/customer';
import mapModifiers from 'utils/functions';

const HomeScreen: React.FC = () => {
  const [step, setStep] = useState(1);
  const [id, setId] = useState<number>();

  const onSubmit = async (params: FormPayload) => {
    const res = await apiSaveCheckIn({
      ...params,
      participants: Number(params.family),
    });
    setId(res.id);
    setStep(2);
  };

  const triggerValid = (data: number) => {
    setId(data);
    setStep(2);
  };

  return (
    <div className={mapModifiers('p-home', step.toString())}>
      <div className="panel">
        <div className="logo">
          <div className="nutifood">
            <Image imgSrc={Nutifood} ratio="nutifood" />
          </div>
          <div className="nnris">
            <Image imgSrc={Nnris} ratio="nnris" />
          </div>
        </div>

        {/* Step 1 */}
        <Form onSubmit={onSubmit} triggerValid={triggerValid} />

        {/* Step2 */}
        {id && (
        <QR id={id} />
        )}

      </div>
      <div className="nnris_big">
        <Image imgSrc={NnrisBig} ratio="nnris_big" />
      </div>
    </div>
  );
};

export default HomeScreen;
